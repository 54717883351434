import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import "./Spectroscopie.css";

const Spectroscopie = () => {
  const [absorbanceData, setAbsorbanceData] = useState([]);
  const [lambdaMax, setLambdaMax] = useState([500]); // Plusieurs λ_max possibles
  const [epsilon, setEpsilon] = useState(1); // Coefficient d'extinction molaire
  const [concentration, setConcentration] = useState(0.01); // Concentration (mol/L)
  const [pathLength, setPathLength] = useState(1); // Longueur de cuve (cm)
  const [showMultiPeak, setShowMultiPeak] = useState(false);

  const generateSpectrum = () => {
    const spectrum = [];
    for (let lambda = 400; lambda <= 700; lambda += 1) {
      let absorbance = 0;

      // Calculer l'absorbance pour chaque λ_max
      lambdaMax.forEach((max) => {
        absorbance +=
          epsilon *
          concentration *
          pathLength *
          Math.exp(-Math.pow(lambda - max, 2) / (2 * Math.pow(30, 2)));
      });

      spectrum.push({ lambda, absorbance: Math.max(absorbance, 0) });
    }
    setAbsorbanceData(spectrum);
  };

  const toggleMultiPeak = () => {
    setShowMultiPeak(!showMultiPeak);
    if (!showMultiPeak) {
      setLambdaMax([450, 550, 600]); // Ajouter plusieurs pics
    } else {
      setLambdaMax([500]); // Revenir à un seul pic
    }
  };

  const spectrumData = {
    labels: absorbanceData.map((point) => point.lambda),
    datasets: [
      {
        label: "Absorbance",
        data: absorbanceData.map((point) => point.absorbance),
        borderColor: "blue",
        fill: true,
        backgroundColor: "rgba(0, 0, 255, 0.1)",
      },
    ],
  };

  return (
    <div className="spectroscopy-container">
      <h1>Simulation : Spectroscopie UV-Visible</h1>
      <div className="spectroscopy-layout">
        <div className="interactive-section">
          <h3>Paramètres de la solution</h3>
          <label>
            λ<sub>max</sub> (nm) :
            <input
              type="text"
              value={lambdaMax.join(", ")}
              onChange={(e) =>
                setLambdaMax(e.target.value.split(",").map((val) => +val.trim()))
              }
            />
          </label>
          <label>
            ε (L·mol⁻¹·cm⁻¹) :
            <input
              type="number"
              value={epsilon}
              onChange={(e) => setEpsilon(+e.target.value)}
              step="0.1"
            />
          </label>
          <label>
            Concentration (mol/L) :
            <input
              type="number"
              value={concentration}
              onChange={(e) => setConcentration(+e.target.value)}
              step="0.01"
            />
          </label>
          <label>
            Longueur de cuve (cm) :
            <input
              type="number"
              value={pathLength}
              onChange={(e) => setPathLength(+e.target.value)}
              step="0.1"
            />
          </label>
          <button onClick={generateSpectrum} className="generate-button">
            Générer le spectre
          </button>
          <button onClick={toggleMultiPeak} className="multi-peak-button">
            {showMultiPeak
              ? "Désactiver les multi-pics"
              : "Activer les multi-pics"}
          </button>
          <div className="plot-section">
            <h3>Spectre UV-Visible</h3>
            <Line
              data={spectrumData}
              options={{
                scales: {
                  x: {
                    title: { display: true, text: "Longueur d'onde (nm)" },
                  },
                  y: {
                    title: { display: true, text: "Absorbance" },
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="cheatsheet-section">
          <h2>📌 Cheat Sheet</h2>
          <p>
            La spectroscopie UV-visible mesure l'absorbance d'une solution pour
            différentes longueurs d'onde. Voici quelques concepts importants :
          </p>
          <h3>Concepts clés</h3>
          <ul>
            <li>
              <strong>Loi de Beer-Lambert :</strong>{" "}
              <code>A = ε × c × l</code>, où :
              <ul>
                <li>
                  <strong>A :</strong> Absorbance (sans unité).
                </li>
                <li>
                  <strong>ε :</strong> Coefficient d’extinction molaire
                  (L·mol⁻¹·cm⁻¹).
                </li>
                <li>
                  <strong>c :</strong> Concentration (mol/L).
                </li>
                <li>
                  <strong>l :</strong> Longueur de cuve (cm).
                </li>
              </ul>
            </li>
            <li>
              <strong>λ<sub>max</sub> :</strong> Longueur d’onde où l’absorbance
              est maximale.
            </li>
            <li>
              <strong>Multi-pics :</strong> Certains composés ont plusieurs
              bandes d'absorption.
            </li>
          </ul>
          <h3>Applications</h3>
          <ul>
            <li>
              Identifier une substance chimique par la position de ses pics
              d'absorption.
            </li>
            <li>
              Quantifier la concentration d'une espèce grâce à l'absorbance.
            </li>
          </ul>
          <h3>Interprétation</h3>
          <p>
            Un spectre avec un pic à une longueur d’onde spécifique indique
            l’absorption préférentielle de cette lumière. L'intensité du pic est
            proportionnelle à la concentration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Spectroscopie;
