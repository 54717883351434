import React from "react";
import "./AboutSite.css";

const AboutSite = () => {
  return (
    <div className="about-site-container">
      <h1>À propos de ce site</h1>
      <p>
        Ce site a été conçu pour combiner pédagogie et interactivité, en
        utilisant des technologies modernes du développement web.
      </p>

      <section className="tech-section">
        <h2>🛠️ Les technologies utilisées</h2>
        <ul>
          <li>
            <strong>React.js (Frontend) :</strong> Une bibliothèque JavaScript
            utilisée pour créer une interface utilisateur interactive et
            réactive. Elle permet de gérer les composants visuels comme les
            graphiques, les sliders et les formulaires.
          </li>
          <li>
            <strong>Flask (Backend) :</strong> Un microframework Python qui gère
            le serveur et les calculs scientifiques. Flask reçoit les
            paramètres envoyés par le frontend, effectue les calculs, puis
            retourne les résultats.
          </li>
          <li>
            <strong>Chart.js :</strong> Une bibliothèque JavaScript intégrée
            avec React pour tracer les graphiques interactifs.
          </li>
          <li>
            <strong>Heroku :</strong> Une plateforme de cloud computing utilisée
            pour héberger et déployer cette application.
          </li>
        </ul>
      </section>

      <section className="site-architecture-section">
        <h2>🏗️ Architecture du site</h2>
        <p>
          Ce site repose sur une architecture client-serveur :
        </p>
        <ul>
          <li>
            <strong>Frontend (React) :</strong> C'est la partie visible de
            l'application, où les utilisateurs interagissent. Elle inclut les
            simulations interactives, les graphiques, et les options
            configurables.
          </li>
          <li>
            <strong>Backend (Flask) :</strong> C'est le cerveau qui effectue les
            calculs complexes. Par exemple :
            <ul>
              <li>
                Calcul des trajectoires (simulation de tir de canon ou de
                pendule).
              </li>
              <li>
                Génération des spectres (spectroscopie UV-visible).
              </li>
            </ul>
          </li>
          <li>
            <strong>Communication via API REST :</strong> Le frontend envoie des
            requêtes HTTP (POST) au backend pour obtenir les résultats de
            calcul. Ces résultats sont ensuite affichés dynamiquement.
          </li>
        </ul>
      </section>

      <section className="purpose-section">
        <h2>🎯 Objectifs du site</h2>
        <p>
          Ce site a été conçu dans un triple objectif :
        </p>
        <ul>
          <li>
            <strong>Pédagogique :</strong> Fournir des outils interactifs pour
            mieux comprendre des concepts scientifiques.
          </li>
          <li>
            <strong>Technique :</strong> Développer et tester des compétences en
            programmation et en développement web.
          </li>
          <li>
            <strong>Exploratoire :</strong> Servir de bac à sable pour tester de
            nouvelles idées et technologies.
          </li>
        </ul>
      </section>

      <section className="future-section">
        <h2>🚀 Améliorations futures</h2>
        <p>
          Voici quelques idées pour les prochaines étapes :
        </p>
        <ul>
          <li>Ajouter plus de simulations interactives.</li>
          <li>Améliorer le design et l'accessibilité.</li>
          <li>Proposer un mode hors ligne grâce à une PWA (Progressive Web App).</li>
          <li>Intégrer des options pour exporter les données des simulations.</li>
        </ul>
      </section>

      <footer className="about-footer">
        <p>
          Ce projet est en constante évolution. N'hésitez pas à envoyer vos
          idées ou suggestions à{" "}
          <a href="mailto:antoinecharvin2@gmail.com">antoinecharvin2@gmail.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default AboutSite;
