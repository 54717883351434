import React, { useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./PcrSimulation.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PcrSimulation = () => {
  const [sequence, setSequence] = useState("ATGCGTACGTAGCTAGCTAGGCTAAGCTTAGCTGACTGATCGATCGATCG");
  const [forwardPrimer, setForwardPrimer] = useState("ATGCGTACG");
  const [reversePrimer, setReversePrimer] = useState("CGATCGATC");
  const [cycles, setCycles] = useState(30);
  const [plotData, setPlotData] = useState(null);

  const runSimulation = async () => {
    try {
      const response = await axios.post("https://mon-app-pedagogique-b5de42f08e76.herokuapp.com/api/pcr-simulation", {
        sequence,
        forward_primer: forwardPrimer,
        reverse_primer: reversePrimer,
        cycles,
      });

      const { temperatures, fluorescence } = response.data;
      setPlotData({
        labels: temperatures,
        datasets: [
          {
            label: "HRM Curve",
            data: fluorescence,
            borderColor: "blue",
            fill: false,
          },
        ],
      });
    } catch (error) {
      console.error("Error running simulation:", error);
    }
  };

  return (
    <div className="pcr-simulation-container">
      <h1>Simulation PCR et HRM</h1>
      <div className="pcr-simulation-layout">
        <div className="simulation-section">
          <div className="pcr-input-container">
            <label>
              Séquence d'ADN :
              <textarea
                value={sequence}
                onChange={(e) => setSequence(e.target.value)}
                rows={4}
              />
            </label>
            <label>
              Amorce Forward :
              <input
                type="text"
                value={forwardPrimer}
                onChange={(e) => setForwardPrimer(e.target.value)}
              />
            </label>
            <label>
              Amorce Reverse :
              <input
                type="text"
                value={reversePrimer}
                onChange={(e) => setReversePrimer(e.target.value)}
              />
            </label>
            <label>
              Nombre de cycles :
              <input
                type="number"
                value={cycles}
                onChange={(e) => setCycles(+e.target.value)}
              />
            </label>
            <button onClick={runSimulation}>Simuler</button>
          </div>
          <div className="plot-container">
            {plotData && (
              <Line
                data={plotData}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      title: { display: true, text: "Température (°C)" },
                    },
                    y: {
                      title: { display: true, text: "Fluorescence Normalisée" },
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="description-section">
          <h2>📚 Théorie</h2>
          <p>
            La réaction en chaîne par polymérase (PCR) est une technique
            fondamentale de biologie moléculaire. Elle permet de copier un
            fragment spécifique d'ADN à partir d'une séquence cible.
          </p>
          <h3>Concepts clés :</h3>
          <ul>
            <li>
              <strong>Séquence d'ADN :</strong> L'ADN est une longue molécule
              contenant des informations génétiques. Dans ce contexte, une
              séquence est une série de bases (A, T, G, C) que nous amplifions.
            </li>
            <li>
              <strong>Amorces (primers) :</strong> Les amorces sont de courtes
              séquences d'ADN qui se lient spécifiquement à la séquence cible
              pour initier l'amplification.
            </li>
            <li>
              <strong>Cycles :</strong> Chaque cycle de PCR double la quantité
              d'ADN cible. Typiquement, 20 à 40 cycles sont utilisés.
            </li>
          </ul>
          <h3>Étapes de la PCR :</h3>
          <ol>
            <li>
              <strong>Dénaturation :</strong> Chauffage de l'ADN à une
              température élevée (≈94°C) pour séparer les deux brins.
            </li>
            <li>
              <strong>Hybridation :</strong> Refroidissement (≈55°C) pour
              permettre aux amorces de s'attacher à leurs cibles spécifiques.
            </li>
            <li>
              <strong>Élongation :</strong> Une enzyme (ADN polymérase)
              synthétise de nouveaux brins complémentaires à ≈72°C.
            </li>
          </ol>
          <h3>HRM (High-Resolution Melting) :</h3>
          <p>
            Une analyse HRM est utilisée après la PCR pour évaluer la
            composition de l'ADN amplifié. Chaque fragment d'ADN a une
            "température de fusion" unique (Tm) basée sur sa séquence et sa
            longueur.
          </p>
          <h3>Comment interpréter la courbe HRM :</h3>
          <ul>
            <li>
              Une augmentation de la température diminue la fluorescence,
              indiquant que les fragments d'ADN se dénaturent.
            </li>
            <li>
              Un <strong>point d'inflexion marqué</strong> (chute rapide de la
              fluorescence) correspond à la température de fusion (Tm) d'un
              fragment.
            </li>
            <li>
              Plusieurs pics ou étapes peuvent indiquer la présence de
              fragments d'ADN de longueurs ou compositions différentes.
            </li>
          </ul>
          <p>
            <strong>Applications :</strong> Identifier des mutations, des
            mélanges d'ADN ou différencier des échantillons.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PcrSimulation;
