import React, { useState } from "react";
import { Scatter } from "react-chartjs-2";
import "./Etalonnage.css";

const Etalonnage = () => {
  const [dataPoints, setDataPoints] = useState([
    { concentration: 0, absorbance: 0 },
    { concentration: 0.2, absorbance: 0.1 },
    { concentration: 0.4, absorbance: 0.2 },
    { concentration: 0.6, absorbance: 0.3 },
    { concentration: 0.8, absorbance: 0.4 },
    { concentration: 1.0, absorbance: 0.5 },
  ]);

  const [unknownAbsorbance, setUnknownAbsorbance] = useState("");
  const [calculatedConcentration, setCalculatedConcentration] = useState(null);
  const [showEquation, setShowEquation] = useState(false);

  const calculateLinearRegression = () => {
    const x = dataPoints.map((point) => point.concentration);
    const y = dataPoints.map((point) => point.absorbance);
    const n = x.length;

    const sumX = x.reduce((a, b) => a + b, 0);
    const sumY = y.reduce((a, b) => a + b, 0);
    const sumXY = x.reduce((sum, xi, i) => sum + xi * y[i], 0);
    const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0);
    const sumY2 = y.reduce((sum, yi) => sum + yi * yi, 0);

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    // Calcul du coefficient de corrélation R²
    const ssTot = sumY2 - (sumY * sumY) / n;
    const ssRes = sumY2 - slope * sumXY - intercept * sumY;
    const rSquared = 1 - ssRes / ssTot;

    return { slope, intercept, rSquared };
  };

  const handleCalculate = () => {
    const { slope, intercept } = calculateLinearRegression();
    const concentration = (unknownAbsorbance - intercept) / slope;
    setCalculatedConcentration(concentration.toFixed(2));
  };

  const handleRemovePoint = (index) => {
    setDataPoints((prev) => prev.filter((_, i) => i !== index));
  };

  const handleExportData = () => {
    const { slope, intercept } = calculateLinearRegression();
    const csvContent =
      "data:text/csv;charset=utf-8," +
      `Concentration,Absorbance\n` +
      dataPoints.map((point) => `${point.concentration},${point.absorbance}`).join("\n") +
      `\n\nEquation de la droite: y = ${slope.toFixed(2)}x + ${intercept.toFixed(2)}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "courbe_etalonnage.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const plotData = {
    labels: dataPoints.map((d) => d.concentration),
    datasets: [
      {
        label: "Points expérimentaux",
        data: dataPoints.map((d) => ({ x: d.concentration, y: d.absorbance })),
        backgroundColor: "blue",
      },
    ],
  };

  const regression = calculateLinearRegression();
  const regressionLine = [
    {
      x: Math.min(...dataPoints.map((d) => d.concentration)),
      y:
        regression.slope *
          Math.min(...dataPoints.map((d) => d.concentration)) +
        regression.intercept,
    },
    {
      x: Math.max(...dataPoints.map((d) => d.concentration)),
      y:
        regression.slope *
          Math.max(...dataPoints.map((d) => d.concentration)) +
        regression.intercept,
    },
  ];

  plotData.datasets.push({
    label: "Droite de tendance",
    data: regressionLine,
    borderColor: "red",
    type: "line",
    fill: false,
  });

  const equationText = `y = ${regression.slope.toFixed(2)}x + ${regression.intercept.toFixed(2)}`;
  const rSquaredText = `R² = ${regression.rSquared.toFixed(2)}`;

  const chartOptions = {
    scales: {
      x: { title: { display: true, text: "Concentration (mol/L)" } },
      y: { title: { display: true, text: "Absorbance" } },
    },
    elements: {
      point: {
        radius: 6,
      },
    },
  };

  return (
    <div className="standard-curve-container">
      <h1>Simulation : Dosage par étalonnage</h1>
      <div className="standard-curve-layout">
        <div className="input-section">
          <h3>Gérer les points expérimentaux</h3>
          <table>
            <thead>
              <tr>
                <th>Concentration (mol/L)</th>
                <th>Absorbance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataPoints.map((point, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="number"
                      value={point.concentration}
                      onChange={(e) =>
                        setDataPoints((prev) =>
                          prev.map((p, i) =>
                            i === index
                              ? { ...p, concentration: parseFloat(e.target.value) }
                              : p
                          )
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={point.absorbance}
                      onChange={(e) =>
                        setDataPoints((prev) =>
                          prev.map((p, i) =>
                            i === index
                              ? { ...p, absorbance: parseFloat(e.target.value) }
                              : p
                          )
                        )
                      }
                    />
                  </td>
                  <td>
                    <button onClick={() => handleRemovePoint(index)}>
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={() =>
              setDataPoints((prev) => [
                ...prev,
                { concentration: 0, absorbance: 0 },
              ])
            }
          >
            Ajouter un point
          </button>
          <button onClick={handleExportData}>Exporter les données</button>
        </div>
        <div className="plot-section">
          <h3>Courbe d'étalonnage</h3>
          <Scatter data={plotData} options={chartOptions} />
          <button
            onClick={() => setShowEquation((prev) => !prev)}
            className="equation-button"
          >
            {showEquation ? "Cacher l'équation" : "Afficher l'équation"}
          </button>
          {showEquation && (
            <p className="equation-text">
              <strong>Équation de la droite de tendance :</strong> {equationText}
              <br />
              <strong>Coefficient de corrélation :</strong> {rSquaredText}
            </p>
          )}
        </div>
      </div>
      <div className="unknown-section">
        <h3>Déterminer une concentration inconnue</h3>
        <label>
          Absorbance inconnue :
          <input
            type="number"
            value={unknownAbsorbance}
            onChange={(e) => setUnknownAbsorbance(e.target.value)}
          />
        </label>
        <button onClick={handleCalculate}>Calculer la concentration</button>
        {calculatedConcentration && (
          <p>
            <strong>Résultat :</strong> La concentration est{" "}
            {calculatedConcentration} mol/L.
          </p>
        )}
      </div>
      <div className="theory-section">
        <h2>📚 Théorie : Loi de Beer-Lambert</h2>
        <p>
          La loi de Beer-Lambert relie l'absorbance (A) d'une solution à sa concentration (c) par la relation suivante :
        </p>
        <p>
          <code>A = ε × c × l</code>
        </p>
        <ul>
          <li>
            <strong>A :</strong> Absorbance (sans unité).
          </li>
          <li>
            <strong>ε :</strong> Coefficient d'extinction molaire (L·mol⁻¹·cm⁻¹).
          </li>
          <li>
            <strong>c :</strong> Concentration de la solution (mol/L).
          </li>
          <li>
            <strong>l :</strong> Longueur de la cuve (cm).
          </li>
        </ul>
        <h3>📖 Fiche recette : Réaliser un titrage par courbe d'étalonnage</h3>
        <ol>
          <li>Préparer une série de solutions de concentrations connues.</li>
          <li>Mesurer leur absorbance à une longueur d'onde donnée.</li>
          <li>Tracer la courbe d'étalonnage en fonction des données.</li>
          <li>Mesurer l'absorbance de la solution inconnue.</li>
          <li>Utiliser l'équation de la droite pour calculer la concentration.</li>
        </ol>
      </div>
    </div>
  );
};

export default Etalonnage;
