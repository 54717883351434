import React, { useState } from "react";
import axios from "axios";
import "./NgsSimulation.css";

const NgSimulation = () => {
  const [sequence, setSequence] = useState("");
  const [numReads, setNumReads] = useState(100);
  const [errorRate, setErrorRate] = useState(0.05);
  const [reads, setReads] = useState([]);

  const runSimulation = async () => {
    try {
      const response = await axios.post("https://mon-app-pedagogique-b5de42f08e76.herokuapp.com/api/nanopore-simulation", {
        sequence,
        num_reads: numReads,
        error_rate: errorRate,
      });

      setReads(response.data);
    } catch (error) {
      console.error("Error running simulation:", error);
    }
  };

  return (
    <div className="ngs-simulation-container">
      <h1>Simulation : Lectures Nanopore (NGS)</h1>
      <div className="ngs-simulation-layout">
        <div className="simulation-section">
          <div className="ngs-input-container">
            <label>
              Séquence d'ADN :
              <textarea
                value={sequence}
                onChange={(e) => setSequence(e.target.value)}
                rows={4}
                placeholder="ATGCGTACGTAGCTAGCTAGGCTAAGCTTAGCTGACTGATCGATCGATCG..."
              />
            </label>
            <label>
              Nombre de lectures :
              <input
                type="number"
                value={numReads}
                onChange={(e) => setNumReads(+e.target.value)}
                min="1"
              />
            </label>
            <label>
              Taux d'erreur (%) :
              <input
                type="number"
                value={errorRate}
                onChange={(e) => setErrorRate(+e.target.value)}
                step="0.01"
                min="0"
                max="1"
              />
            </label>
            <button onClick={runSimulation}>Lancer la simulation</button>
          </div>
          <div className="output-section">
            {reads.length > 0 && (
              <>
                <h3>Lectures générées :</h3>
                <ul>
                  {reads.slice(0, 10).map((read, index) => (
                    <li key={index}>
                      <code>{read}</code>
                    </li>
                  ))}
                </ul>
                <p>
                  <strong>Total des lectures générées :</strong> {reads.length}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="cheatsheet-section">
          <h2>📚 NGS (Nanopore Sequencing)</h2>
          <p>
            La technologie de séquençage par Nanopores est utilisée pour lire de longues séquences d'ADN en temps réel. Contrairement aux technologies à court fragment, elle ne nécessite pas de PCR.
          </p>
          <h3>Principes de base :</h3>
          <ul>
            <li>
              Une molécule d'ADN traverse un nanopore sous l'action d'un courant électrique.
            </li>
            <li>
              Les variations du courant permettent de détecter la séquence nucléotidique.
            </li>
            <li>
              La technologie est idéale pour le séquençage d'organismes complexes, la détection des variations structurelles et la résolution des haplotypes.
            </li>
          </ul>
          <h3>Interprétation des données :</h3>
          <ul>
            <li>
              Chaque lecture correspond à une portion aléatoire de la séquence d'ADN d'entrée.
            </li>
            <li>
              Le taux d'erreur peut inclure des substitutions, des insertions et des délétions.
            </li>
            <li>
              Une couverture élevée améliore la précision du consensus.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NgSimulation;
