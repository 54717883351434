import "normalize.css"; // Import de Normalize.css
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideBar from "./components/SideBar";
import HomePage from "./components/HomePage";
import WaveSimulation from "./components/WaveSimulation";
import WaveSuperposition from "./components/WaveSuperposition"; 
import PendulumSimulation from "./components/PendulumSimulation";
import CannonSimulation from "./components/CannonSimulation";
import PcrSimulation from "./components/PcrSimulation";
import NgsSimulation from "./components/NgsSimulation";
import Etalonnage from "./components/Etalonnage";
import Spectroscopie from "./components/Spectroscopie";
import AboutSite from "./components/AboutSite";



const App = () => {
  return (
    <Router>
      <div style={{ display: "flex" }}>
        <SideBar />
        <div style={{ marginLeft: "250px", padding: "20px", width: "100%" }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/wavesimulation" element={<WaveSimulation />} />
            <Route path="/wavesuperposition" element={<WaveSuperposition />} />
            <Route path="/pendulumsimulation" element={<PendulumSimulation />} />
            <Route path="/cannonsimulation" element={<CannonSimulation />} />
            <Route path="/pcrsimulation" element={<PcrSimulation />} />
            <Route path="/ngssimulation" element={<NgsSimulation />} />
            <Route path="/etalonnage" element={<Etalonnage />} />
            <Route path="/spectroscopie" element={<Spectroscopie />} />
            <Route path="/aboutsite" element={<AboutSite />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
