import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage-container">
      <header className="homepage-header">
        <h1>Bienvenue dans l'application de simulations pédagogiques</h1>
        <p>
          Je m'appelle <strong>Antoine Charvin</strong>, titulaire d'un Master en Physique Numérique, et actuellement ingénieur logiciel au <strong>CNRS</strong>. 
          Cette plateforme a été conçue pour combiner mes passions pour l'enseignement, la physique, et le développement logiciel.
        </p>
      </header>

      <section className="homepage-content">
        <h2>🌟 Pourquoi cette application ?</h2>
        <p>
          Cette application a été développée avec plusieurs objectifs en tête :
        </p>
        <ul>
          <li>
            <strong>Éducation :</strong> Aider une élève que je suis dans le cadre de cours particuliers (niveau Première/Terminale) en proposant des outils visuels pour comprendre des concepts.
          </li>
          <li>
            <strong>Développement professionnel :</strong> Enrichir mes compétences techniques en travaillant avec des technologies modernes comme :
            <ul>
              <li>🌐 Frontend : <strong>React.js</strong> (JavaScript)</li>
              <li>🛠️ Backend : <strong>Flask</strong> (Python)</li>
            </ul>
          </li>
          <li>
            <strong>Bac à sable (sandbox) :</strong> Tester et expérimenter de nouvelles idées en programmation pour explorer des concepts ou de nouvelles technologies.
          </li>
        </ul>
      </section>

      <section className="homepage-simulations">
        <h2>🔍 Simulations pédagogiques</h2>
        <p>
          Ces simulations sont conçues pour aider les élèves à comprendre des concepts fondamentaux en physique, de manière visuelle et interactive :
        </p>
        <ul>
          <li>📢 <strong>Propagation d'ondes :</strong> Explorez différents types d'ondes et ajustez leurs paramètres.</li>
          <li>🕰️ <strong>Simulation de pendule :</strong> Étudiez le mouvement oscillatoire avec ou sans amortissement.</li>
          <li>🎯 <strong>Tir de canon :</strong> Découvrez les principes de la balistique à travers une simulation dynamique.</li>
        </ul>
        <p>Cliquez sur une simulation dans le menu pour commencer votre exploration !</p>
      </section>

      <section className="homepage-simulations">
        <h2>🔬 Simulations d'exploration</h2>
        <p>
          Ces simulations sont des projets personnels où j'explore des concepts avancés en biologie ou en physique computationnelle :
        </p>
        <ul>
          <li>🧬 <strong>PCR et courbes HRM :</strong> Simulez une amplification d'ADN et analysez les courbes de fusion pour mieux comprendre la biologie moléculaire.</li>
          <li>📖 <strong>NGS (Next-Generation Sequencing) :</strong> Explorez les principes de la lecture ADN avec les technologies modernes comme les nanopores.</li>
        </ul>
        <p>
          Ces projets me permettent de découvrir et approfondir des domaines scientifiques tout en développant mes compétences techniques.
        </p>
      </section>

      <section className="homepage-contact">
        <h2>📬 Nous contacter</h2>
        <p>
          Une idée ? Une question ? Une suggestion pour une nouvelle simulation ? N'hésitez pas à me contacter pour partager vos retours !
        </p>
        <a href="mailto:antoinecharvin2@gmail.com" className="contact-link">
          Contactez-moi
        </a>
      </section>
    </div>
  );
};

export default HomePage;
